export function setupDatePicker() {
  $.datepicker.regional['fr'] = {
      closeText: 'Fermer', // set a close button text
      currentText: "Aujourd'hui", // set today text
      monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin', 'Juillet','Août','Septembre','Octobre','Novembre','Décembre'], // set month names
      monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jun','Jul','Aou','Sep','Oct','Nov','Dec'], // set short month names
      dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'], // set days names
      dayNamesShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'], // set short day names
      dayNamesMin: ['Di','Lu','Ma','Me','Je','Ve','Sa'], // set more short days names
      dateFormat: 'yy-mm-dd' // set format date
  };

  $.datepicker.setDefaults($.datepicker.regional['fr']);

  var options = {
      format: "yyyy-mm-dd",
      language: "fr"
    };

  $('input.datepicker').each( function () {
    var thisOptions = options;

    if ($(this).data('startdate'))
      thisOptions["minDate"] = $(this).data('startdate');

    if ($(this).data('enddate'))
      thisOptions["maxDate"] = $(this).data('enddate');

    thisOptions["onSelect"] = (function(dateText) {
      var currentValue = $(this).data("datepicker-value");

      if (dateText != currentValue) {
        $(this).data("datepicker-value", dateText);
        var element = $(this).get(0);
        element.dispatchEvent(new Event('change'));
      }

     $(this).focus();
     $(this).trigger('focusout');
    });

    $(this).datepicker(thisOptions);  
    $(this).data("datepicker-value", $(this).val());
  });
}