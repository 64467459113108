var criteriaObj = $(".password-criteria");

var passwordField = $(criteriaObj.data("password-field"));
var passwordConfirmationField = $(criteriaObj.data("password-confirmation-field"));

$(passwordField).on("keyup", function () {
  checkPassword(passwordField, passwordConfirmationField);
});

$(passwordConfirmationField).on("keyup", function() {
  checkPassword(passwordField, passwordConfirmationField);
});

function checkPassword(varPasswordField, varPasswordConfirmationField) {
  var criteriaObj = $(".password-criteria");
  var minimumLength = $(criteriaObj).data("minimum-length");
  var maximumLength = $(criteriaObj).data("maximum-length");
  var digits = $(criteriaObj).data("password-digits");
  var lowercase = $(criteriaObj).data("password-lowercase");
  var uppercase = $(criteriaObj).data("password-uppercase");
  var symbol = $(criteriaObj).data("password-symbol");

  var password = $(varPasswordField).val();
  var passwordConfirmation = $(varPasswordConfirmationField).val();

  var success = (password == passwordConfirmation);
  updatePasswordElement(success, "#li-password-confirmation", "#password-confirmation-mark");

  if (minimumLength || ((maximumLength) && (maximumLength > 0))) {
    var match = true;

    if (minimumLength)
      match = match && (password.length >= minimumLength);

    if ((maximumLength) && (maximumLength > 0))
      match = match && (password.length <= maximumLength);

    success = success && match;
    updatePasswordElement(match, "#li-password-length", "#password-length-mark");
  }


  if ((digits) && (digits > 0)) {
    var chars = password.match(/[0-9]/g);
    var match = false;

    if (chars)
      match = (chars.length >= digits);

    success = success && match;
    updatePasswordElement(match, "#li-password-number", "#password-number-mark");
  }

  if ((lowercase) && (lowercase > 0)) {
    var chars = password.match(/[a-z]/g);
    var match = false;

    if (chars)
      match = (chars.length >= lowercase);

    success = success && match;
    updatePasswordElement(match, "#li-password-lowercase", "#password-lowercase-mark");
  }

  if ((uppercase) && (uppercase > 0)) {
    var chars = password.match(/[A-Z]/g);
    var match = false;

    if (chars)
      match = (chars.length >= uppercase);

    success = success && match;
    updatePasswordElement(match, "#li-password-uppercase", "#password-uppercase-mark");
  }

  if ((symbol) && (symbol > 0)) {
    var chars = password.match(/[^A-Za-z0-9]/g);
    var match = false;

    if (chars)
      match = (chars.length >= symbol);

    success = success && match;
    updatePasswordElement(match, "#li-password-symbol", "#password-symbol-mark");
  }

  if (success) {
    $(".password-validator").removeClass("alert-danger");
    $(".password-validator").addClass("alert-success");
  }
  else {
    $(".password-validator").addClass("alert-danger");
    $(".password-validator").removeClass("alert-success");    
  }
}

function updatePasswordElement(match, item, mark) {
  if (match) {
    $(item).addClass("password-match");
    $(mark).removeClass("fa-square");
    $(mark).addClass("fa-check-square");
  }
  else {
    $(item).removeClass("password-match");
    $(mark).addClass("fa-square");
    $(mark).removeClass("fa-check-square");
  }
}