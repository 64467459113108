/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//import 'jquery';
//import 'popper.js'
require("rails-ujs").start()
require("@rails/activestorage").start()

global.$ = global.jQuery = $;
window.jQuery = window.$ = require('jquery')

import 'select2'

import "core-js/stable"
import "regenerator-runtime/runtime"

require("jquery-ui/jquery-ui.min");
require("jquery-ui/jquery-ui.min.css");

//require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true,    /jquery-ui\.css/ );
//require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true,    /jquery-ui\.theme\.css/ );

require('./stylesheets/application.scss')
require("@rails/actiontext")

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import * as jq from 'jquery-local'

$(document).ready(function () {
  $(document).on({
    'show.bs.modal': function() {
      var zIndex = 1040 + (10 * $('.modal:visible').length);
      $(this).css('z-index', zIndex);
      setTimeout(function() {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
      }, 0);
    },
    'hidden.bs.modal': function() {
      if ($('.modal:visible').length > 0) {
        // restore the modal-open class to the body element, so that scrolling works
        // properly after de-stacking a modal.
        setTimeout(function() {
          $(document.body).addClass('modal-open');
        }, 0);
      }
    }
  }, '.modal');

  $('.modal').on("hidden.bs.modal", function (e) { 
      if ($('.modal:visible').length) { 
          $('body').addClass('modal-open');
      }
  });

  $( "select.jq-select2" ).select2({
    theme: 'bootstrap4',
    language: 'fr'
  });

  $( "select.jq-tag-select2" ).select2({
    theme: 'bootstrap4',
    language: 'fr',
    tags: true,
    tokenSeparators: [','],
    createTag: function (params) {
      return {
        id: "<<<" + params.term + ">>>",
        text: params.term
      }
    }    
  });

  jq.setupDatePicker();  

  if ($(".password-validator")) {
    require("password-validator");
  }
});

$(function(){
    var lastUpdate = 0;

    // !!!!! Also update session cookie expiration time in config/initializers/session_store.rb !!!
    var interval = 600000 - 15000; //10 minutes minus 15 seconds
    // !!!!! Also update session cookie expiration time in config/initializers/session_store.rb !!!


    if (interval <= 0)
      interval = 1000;

    var checkInterval = setInterval(function(){
       if(new Date().getTime() - lastUpdate > interval){
           clearInterval(checkInterval);
       }else{   
            $.get('/keep_alive');
       }
    }, interval);

    $(document).keydown(function(){
         lastUpdate = new Date().getTime();
    });
});
  
